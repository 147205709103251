import React from 'react';

import SubpageLayout from "../components/subpage-layout"
import Seo from "../components/seo"

const Privacy = () => (
   <SubpageLayout>
     <Seo title="Privacy Policy" />
      <header className="subpage--content-header">
        <h1>Privacy Policy</h1>
        <h2>
          This website is owned by Richland Library and maintained by library
          staff. The site's purpose is to provide information to the public
          regarding library services, resources and events.
        </h2>
      </header>

      <section>
        <h3>Information Collection and Use</h3>
        <p>
          Richland Library collects data to measure usefulness of the
          information contained on our site, and the library is the sole owner
          of that information. It is not shared or sold to others.
        </p>

        <h3>Cookies</h3>
        <p>
          A cookie is a piece of data stored on the user's hard drive containing
          information about the user. Usage of a cookie is in no way linked to
          any personally identifiable information while on our site.
        </p>

        <h3>Log Files</h3>
        <p>
          We use IP addresses to analyze trends and track a user's movement
          within our site. This helps us determine which content our customers
          find relevant, allowing us to continue to improve those areas. IP
          addresses are not linked to personally identifiable information.
        </p>

        <h3>Links</h3>
        <p>
          This website contains links to other sites. Please be aware that
          Richland Library is not responsible for the privacy practices of other
          sites. We encourage you to be knowledgeable about the privacy
          statements of any website that collects personally identifiable
          information.
        </p>

        <h3>Enforcement</h3>
        <p>
          If for some reason you believe <a href="https://libraryintercept.com">libraryintercept.com</a> has
          not followed these policies, please notify us by e-mail found on
          the <a href="/contact/">Contact</a> page. We will promptly address your concern.
        </p>
      </section>
   </SubpageLayout>

)

export default Privacy